import tw, { styled } from 'twin.macro';

export const Wrapper = styled.footer(() => {
  const out = [tw`flex flex-col w-full bg-gray-light mt-4 sticky`, { bottom: 0 }];
  return out;
});

export const BoxWrap = styled.div(() => {
  const out = [
    tw`flex justify-center bg-shadow_black mx-auto w-52 mt-4 mx-auto relative border-white border-2 border-solid`,
    {
      '&:before': [
        tw`bg-white block absolute`,
        {
          content: "''",
          width: 2,
          left: 'calc(50% - 1px)',
          transform: 'translateX(-50%)',
          height: 34,
          top: -34,
        },
      ],
    },
  ];
  return out;
});

export const BoxTitle = styled.h3(() => tw`font-secondary text-white text-3xl text-center`);

export const GridWrapper = styled.div(() => {
  const out = [
    tw`flex flex-col md:flex-row flex-wrap justify-center relative mt-8 mb-10 mx-auto`,
    {
      a: [
        tw`block text-center bg-maroon border border-white lg:border-r-0 lg:border-l-0 text-white text-2xl font-primary font-bold px-4 py-2 hover:bg-maroon-dark hover:no-underline`,
        {
          minWidth: 320,
          '&:first-of-type': tw`bg-bordeaux hover:bg-bordeaux-dark border-r border-l`,
          '&:last-of-type': tw`bg-teal hover:bg-teal-dark border-r border-l`,
        },
      ],
    },
  ];
  return out;
});

export const FooterWrapper = styled.div(() => tw`block bg-bordeaux-dark py-4`);

export const FooterLogo = styled.div(() => tw`flex justify-center bg-transparent py-4`);

export const Disclaimer = styled.p(() => tw`text-center text-white`);

export const FooterMenuBox = styled.nav(() => {
  const out = [
    tw`flex flex-col md:flex-row bg-bordeaux-dark py-4 justify-center`,
    {
      a: tw`text-white text-xl px-2 text-center hover:text-gray-light`,
    },
  ];
  return out;
});
