import dynamic from 'next/dynamic';

import { Wrapper } from './style';

const Loader = dynamic(() => import('../../components/Loader'), {
  loading: () => <p>....Loading.....</p>,
});

const Box = dynamic(() => import('./box').then((mod)=>mod.Box), {
  loading: () => <Loader isLoading />,
});

const Grid = dynamic(() => import('./grid').then((mod)=>mod.Grid), {
  loading: () => <Loader isLoading />,
});

const Footer = dynamic(() => import('./footer').then((mod)=>mod.Footer), {
  loading: () => <Loader isLoading />,
});
export interface IMegaFooterProps {
  title: string;
}

export const MegaFooter = ({ title }: IMegaFooterProps) => {
  return (
    <Wrapper>
      <Box title={title} />
      <Grid />
      <Footer />
    </Wrapper>
  );
};
